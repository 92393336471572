import { useEffect, useState } from "react"
import ContestContainer from "../ContestContainer"
import Feed from "../Feed"
import LogoText from "../LogoText"
import FeaturedDrawing from "../../types/FeaturedDrawing"

const App = () => {
    const [isContestVisible, setIsContestVisible] = useState(false)
    const [featured, setFeatured] = useState<FeaturedDrawing[]>([])

    const handleHideContestContainer = () => {
        setIsContestVisible(false)
    }

    useEffect(() => {
        const fetchFeatured = async () => {
            const response = await fetch("/featured.json");
            if (!response.ok) {
                throw new Error("Failed to fetch featured drawings");
            }

            const featured: FeaturedDrawing[] = await response.json();
            return featured;
        }
        fetchFeatured().then(setFeatured)
    }, [])

    return (
        <div className="h-full max-w-3xl mx-auto">
            <div className="pt-10 h-full no-scrollbar overflow-hidden overflow-y-scroll">
                <div className="mx-3 mb-6">
                    <div
                        className="dark:text-gray-50 h-12 animate-in zoom-in">
                        <a href="/app">
                            <LogoText />
                        </a>
                    </div>
                </div>

                <div className="p-3">
                    <div className="text-3xl text-gray-700 mb-3">
                        Featured Drawings
                    </div>
                    <div className="flex space-x-3 touch-pan-x overflow-x-auto no-scrollbar">
                        {featured.map((featuredDrawing, index) => (
                            <div key={index} className="w-1/3 flex-shrink-0 space-y-2 ">
                                <div className="rounded-xl overflow-hidden shadow">
                                    <img src={featuredDrawing.path} alt={featuredDrawing.name} />
                                </div>
                                <div className="text-gray-500 text-sm">
                                    {[featuredDrawing.name, featuredDrawing.location].filter(Boolean).join(", ")}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {isContestVisible && (
                    <div onClick={handleHideContestContainer} className="p-3">
                        <ContestContainer>
                            <div className="uppercase text-center text-5xl font-bold three-dee-shadow border-white text-pink-300 shadow-blue-400">
                                Weekly drawing contest!
                            </div>
                            <div className="text-lg text-pink-400 font-bold text-center">
                                Introducing the new weekly drawing contest!
                            </div>
                            <div className="text-center text-lg text-pink-400 ">
                                The winning drawing will be featured here for a week.
                                Submit your completed drawings with the new contest button in the editor.
                            </div>
                        </ContestContainer>
                    </div>
                )}

                <div className="no-scrollbar p-3">
                    <div className="text-3xl text-gray-700 mb-3">
                        Your Feed
                    </div>
                    <Feed />
                </div>
            </div>
        </div>
    )
}

export default App
